#mainContainer {
  margin-top: 91px;
  padding-top: 35px;
}

.back-button {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36'%3E%3Cpath d='M23.994 25.937L16.074 18l7.92-7.937-2.438-2.438L11.184 18l10.372 10.375z' fill='%23008eff'/%3E%3Cpath d='M36 0v36H0V0z' fill='none'/%3E%3C/svg%3E")
    center no-repeat;
  background-color: #ffffff;
  border-radius: 14px;
  border-color: #008eff;
  color: #008eff;
  filter: drop-shadow(0px 3px 6px #00000029);
  font-size: 19.5px;
  height: 51px;
  margin: 7.5px;
  padding: 10.5px;
  width: 37.5px;
}

.back-button:hover {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36'%3E%3Cpath d='M23.994 25.937L16.074 18l7.92-7.937-2.438-2.438L11.184 18l10.372 10.375z' fill='%23ffffff'/%3E%3Cpath d='M36 0v36H0V0z' fill='none'/%3E%3C/svg%3E")
    center no-repeat;
  background-color: #008eff;
  border-color: #008eff;
}
